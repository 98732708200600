/* eslint-disable max-len */

import React from "react";
import { useState } from "react";
import Layout from "../components/Layout";
import {
  Heading,
  Box,
  Container,
  Flex,
  HStack,
  Link,
  Button,
  AspectRatio,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaPlay, FaChevronRight } from "react-icons/fa";
import useTranslation from "next-translate/useTranslation";
import NextImage from "next/image";
import { BannerCta, UiLink, RegisterForm } from "../components";
import dynamic from "next/dynamic";
import heroBG from "../assets/images/home/urban_greenhouse_couple.jpg";

const ModalVideo: any = dynamic(() => import("react-modal-video"), {
  ssr: false,
});

const IndexPage = (): JSX.Element => {
  const { t, lang } = useTranslation("home");
  const [videoVisible, setVideoVisible] = useState<boolean>(false);
  const [mobileVideoVisible, setMobileVideoVisible] = useState<boolean>(false);

  const registrationUrl = `https://my.farmhub.ag/register?lang=${lang}`;

  return (
    <Layout title={t("title")} description={t("desc")}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: "FarmHub",
            url: "https://farmhub.ag",
          }),
        }}
      />
      <ModalVideo
        channel="youtube"
        youtube={{ autoplay: 1 }}
        isOpen={videoVisible}
        videoId="2gWLUIVEHW0"
        onClose={() => setVideoVisible(false)}
      />
      <Box position="relative" overflow="hidden">
        <Box
          _before={{
            content: '""',
            bgImage: heroBG.src,
            bgSize: "cover",
            pos: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.5,
            zIndex: -1,
          }}
          _after={{
            content: '""',
            bg: "black",
            pos: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: -2,
          }}
        ></Box>
        <Container zIndex={100} maxW="6xl" pt={32} pb={32}>
          <Flex gridGap={4} flexDir={{ base: "column", md: "row" }}>
            <Box flex={1}>
              <Heading
                mb={6}
                as="h1"
                fontSize={{ base: "3rem", lg: "4rem" }}
                color="white"
                lineHeight="shorter"
                textShadow="2px 2px 0px rgba(0,0,0,0.7)"
              >
                {t("unleash-aquaponics")}
              </Heading>
              <Heading
                as="h3"
                fontSize={{ base: "1.2rem", lg: "1.5rem" }}
                color="white"
                fontWeight="light"
                textShadow="0 0 10px rgba(0,0,0,0.5)"
              >
                {t("unleash-aquaponics-desc")}
              </Heading>
              <HStack mt={20}>
                <Button
                  as={Link}
                  href={registrationUrl}
                  colorScheme="gray"
                  _hover={{
                    textDecoration: "none",
                  }}
                  size="lg"
                >
                  {t("start-free-trial")}
                </Button>
                <Button
                  as={Link}
                  onClick={() => setVideoVisible(!videoVisible)}
                  variant="ghost"
                  color="white"
                  size="lg"
                  rightIcon={<FaPlay />}
                  _hover={{
                    color: "gray.900",
                    bg: "gray.100",
                    textDecoration: "none",
                  }}
                >
                  {t("common:watch-video")}
                </Button>
              </HStack>
            </Box>
            <RegisterForm source="www.hero" />
          </Flex>
        </Container>
      </Box>

      <Box
        mt={10}
        textAlign={"center"}
        fontWeight="bold"
        textTransform={"uppercase"}
        color="gray.600"
      >
        - {t("trust-logos-header")} -
      </Box>
      <HStack wrap="wrap" mt={4} pb={5} gridGap={4} justifyContent="center">
        <NextImage
          alt={t("aquaponics-association")}
          height={40}
          width={120}
          src={require("../assets/images/logos/aquaponics-association.png")}
          priority={true}
        />
        <NextImage
          alt={"Santa Fe Community College"}
          height={40}
          width={120}
          src={require("../assets/images/logos/sfcc.svg")}
          priority={true}
        />
        <NextImage
          alt={t("aquaponics-source")}
          height={40}
          width={150}
          src={require("../assets/images/logos/aquaponicsource.png")}
          priority={true}
        />
        <NextImage
          alt="Enterprise Aquatics"
          height={67}
          width={160}
          src={require("../assets/images/logos/enterprise-aquatics.png")}
          priority={true}
        />
        <NextImage
          alt={t("european-aquaponics-association")}
          height={40}
          width={120}
          src={require("../assets/images/logos/european-association.png")}
          priority={true}
        />
        <NextImage
          alt={t("regen-aquaculture")}
          height={40}
          width={192}
          src={require("../assets/images/logos/regenaquaculture.png")}
          priority={true}
        />
        <NextImage
          alt={t("tulua")}
          height={40}
          width={120}
          src={require("../assets/images/logos/tulua.png")}
          priority={true}
        />
        <NextImage
          alt={t("roots")}
          height={40}
          width={132}
          src={require("../assets/images/logos/roots.png")}
          priority={true}
        />
      </HStack>
      <Box>
        <Container maxW="6xl" pt={{ base: 10, md: 20 }}>
          <VStack spacing={6} textAlign="center">
            <Heading maxW="2xl" size="2xl" as="h2" fontWeight="extrabold">
              {t("powerful-aquaponics-app")}
            </Heading>
            <Text maxW="xl">{t("powerful-aquaponics-app-desc")}</Text>
            <UiLink href="/features" mb={10}>
              <Button
                rightIcon={<FaChevronRight />}
                size="lg"
                variant="ghost"
                colorScheme="brand"
              >
                {t("view-features")}
              </Button>
            </UiLink>
            <Box shadow="2xl">
              <NextImage
                alt={t("common:desktop-screenshot")}
                width={1308}
                height={734}
                src={require("../assets/images/demos/farmhub-screen.gif")}
              />
            </Box>
          </VStack>
        </Container>
      </Box>
      <Box
        pb={{ base: 10, md: 20 }}
        bgGradient="linear(to-r, brand.900, brand.400)"
        mt={-40}
        pt={60}
      >
        <Container maxW="6xl">
          <Flex direction={{ base: "column", md: "row" }} gridGap={10}>
            <Box flex={1}>
              <Heading
                as="h2"
                size="2xl"
                mb={10}
                color="white"
                fontWeight="extrabold"
              >
                {t("tasks-intro")}
              </Heading>
              <Box color="gray.100" fontSize={"lg"}>
                {t("tasks-intro-desc")}

                <VStack align="stretch" mt={4}>
                  <Box
                    bgImage="linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.05))"
                    py={4}
                    px={6}
                    rounded="xl"
                  >
                    {t("tasks-intro-1")}
                  </Box>
                  <Box
                    bgImage="linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.05))"
                    py={4}
                    px={6}
                    rounded="xl"
                  >
                    {t("tasks-intro-2")}
                  </Box>
                  <Box
                    bgImage="linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.05))"
                    py={4}
                    px={6}
                    rounded="xl"
                  >
                    {t("tasks-intro-3")}
                  </Box>
                </VStack>
              </Box>
            </Box>
            <Box flex={1}>
              <NextImage
                alt="Alerts, Tasks, Activities"
                width={540}
                height={424}
                src={require("../assets/images/product/activity-alerts-tasks.png")}
              />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box pt={20}>
        <Container maxW="6xl">
          <AspectRatio ratio={16 / 9} rounded="xl" overflow="auto">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oCnhkWT79ck?controls=1&loop=1&autoplay=1&mute=1&cc_load_policy=1&rel=0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </AspectRatio>
        </Container>
      </Box>
      <Container maxW="6xl" py={{ base: 10, md: 20 }}>
        <ModalVideo
          channel="youtube"
          youtube={{ autoplay: 1 }}
          isOpen={mobileVideoVisible}
          videoId="xQDPVQ4M35I"
          onClose={() => setMobileVideoVisible(false)}
        />

        <SimpleGrid gridGap={10} alignItems="center" columns={{ md: 2 }}>
          <Box textAlign={{ base: "center", md: "left" }}>
            <Heading as="h2" mb={4} fontWeight="extrabold">
              {t("access-aquaponic-system")}
            </Heading>
            <Text>{t("access-aquaponic-system-desc")}</Text>
            <Button
              bgGradient="linear(to-r, brand.900, brand.700)"
              onClick={() => setMobileVideoVisible(!mobileVideoVisible)}
              colorScheme="brand"
              size="lg"
              mt={10}
              rightIcon={<FaPlay />}
            >
              {t("common:watch-video")}
            </Button>
          </Box>
          <Box textAlign="center">
            <NextImage
              alt={t("common:mobile-screenshot")}
              width={400}
              height={467}
              src={require("../assets/images/product/phone-2.png")}
            />
          </Box>
        </SimpleGrid>
      </Container>
      <BannerCta
        title={t("common:start-free-trial-today")}
        ctaText={t("common:sign-up")}
        ctaUrl={registrationUrl}
      />
    </Layout>
  );
};

export default IndexPage;
